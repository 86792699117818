<template>
  <div class="inviteFriend -mt-20px" :class="{ 'd-none': isRoot, 'd-md-block': true }">
    <div class="section-header d-none d-lg-flex">
      <div class="header-title">Giới thiệu bạn</div>
    </div>
    <hr class="d-none d-lg-block" />
    <div class="section-content w-100 flex-column">
      <div class="banner -mx-15px">
        <img
          :src="getBanner.m_invite.pos_01[0].image_url"
          alt="banner moi ban be"
          class="mw-100 pointer d-lg-none"
          @click="$router.push('/thong-tin/moi-ban-be')"
        />
        <img
          :src="getBanner.w_invite.pos_01[0].image_url"
          alt="banner moi ban be"
          class="mw-100 pointer d-none d-lg-block"
          @click="$router.push('/thong-tin/moi-ban-be')"
        />
      </div>
      <div class="inviteInfo">
        <div class="inviteInfo__content">
          <p>Mã giới thiệu</p>
          <p>{{ referralData.invite_code }}</p>
        </div>
        <button class="btn d-md-none" @click="handleShareNow">Giới thiệu ngay</button>
        <div class="inviteInfo__groupAction d-none d-md-block">
          <a class="inviteInfo__groupAction__btn fb" @click="handleShareFb">
            <img src="@/assets/img/icon/icon_invite-fb.svg" alt="icon fb" />

            Mời qua facebook</a
          >
          <a class="inviteInfo__groupAction__btn mail" :href="dataMail">
            <img src="@/assets/img/icon/icon_invite-email.svg" alt="icon email" /> Mời qua email</a
          >
          <a class="inviteInfo__groupAction__btn copy" @click="copyTextToClipboard">
            <img width="15px" height="15px" src="@/assets/img/icon/icon_check.svg" alt="icon copied" v-if="isCopy" />
            <img src="@/assets/img/icon/icon_invite-copy.svg" alt="icon copy" v-else />
            copy link</a
          >
        </div>
      </div>
      <div class="newNoti">
        <h5>Thông báo</h5>
        <ul>
          <li>
            Từ 0 giờ ngày 1/6/2021 Tài Lộc sẽ kết thúc chương trình nhận hoa hồng khi giới thiệu bạn bè mua vé số trên
            Tài Lộc đối với những tài khoản đăng ký mới.
          </li>
          <li>
            Những người bạn mời trước ngày 1/6/2021 thông qua chương trình Giới thiệu bạn bè thì bạn vẫn sẽ được nhận
            hoa hồng khi người bạn mua vé.
          </li>
        </ul>
      </div>

      <div class="groupInfoCtn">
        <div class="groupInfo">
          <p class="groupInfo__title">Hoa hồng của bạn</p>
          <div class="groupInfo__boxInfo">
            <div>
              <span>Tổng hoa hồng đã giới thiệu</span>
              <span class="ml-auto bold">{{ formatPrice(referralData.total_commission) }}</span>
            </div>
            <hr />
            <div>
              <span>Hoa hồng chưa nhận còn lại</span>
              <span class="ml-auto bold m-text-primary">{{ formatPrice(referralData.remain_commission) }}</span>
            </div>
            <div>
              <button class="btn w-100 addTowallet" @click="moveRemainCommission">
                Chuyển hoa hồng vào ví
              </button>
            </div>
          </div>
        </div>
        <div class="groupInfo">
          <p class="groupInfo__title">Thông tin giới thiệu bạn</p>
          <div class="groupInfo__boxInfo">
            <div class="d-lg-none" @click="linkToList">
              <span>Số bạn bè đã đăng ký</span>
              <span class="nextIcon bold d-none d-lg-block p-0">{{ referralData.total_friends }}</span>
              <span class="ml-auto nextIcon bold pointer d-lg-none">{{ referralData.total_friends }}</span>
            </div>
            <div class="d-none d-lg-flex">
              <span>Số bạn bè đã đăng ký</span>
              <span class="nextIcon bold d-none d-lg-block p-0">{{ referralData.total_friends }}</span>
              <span v-if="referralData.total_friends > 0" @click="linkToList" class="bold btnShowList pointer"
                >Xem danh sách</span
              >
            </div>
            <hr />
            <div>
              <span>Số tiền bạn bè đã mua vé</span>
              <span class="ml-auto bold">{{ formatPrice(referralData.total_revenue) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommonModal
      title="Thông báo"
      @updateOpen="setModalNoti"
      :isOpen="isOpenNoti"
      confirmTextBtn="Đóng"
      :isCancleBtn="false"
      footerClass="customButton"
      dialogClass="customDialogInviteFriend"
    >
      <div>
        Bạn không có hoa hồng chưa nhận. Bạn sẽ nhận được hoa hồng khi bạn bè bạn đã mời mua vé trên Tài Lộc.
      </div>
    </CommonModal>
    <div id="myInviteCode" />
  </div>
</template>

<script>
import ReferralService from '../../../api/referral';
import { BASE_URL } from '../../../common/global';
import { Noti } from '../../../main';
import CommonModal from '../../CommonModal';
export default {
  components: { CommonModal },

  data() {
    return {
      referralData: {
        invite_code: '',
        remain_commission: 0,
        total_commission: 0,
        total_friends: 0,
        total_revenue: 0
      },
      isCopy: false,
      isOpenNoti: false
    };
  },
  created() {},
  mounted() {
    this.getInitData();
  },
  computed: {
    isApp() {
      return this.$store.getters.getterIsApp;
    },
    isRoot() {
      return this.$route.path == '/tai-khoan/' || this.$route.path == '/tai-khoan';
    },
    accountId() {
      return this.$store.getters.getterAccountId;
    },
    dataMail() {
      let mailto = '';
      let subject = 'Mua vé số Vietlott online với ứng dụng Tài Lộc';
      let body =
        'Mình đang mua vé số Vietlott trên ứng dụng Tài Lộc, hãy đăng ký và cùng nắm bắt vận may nhé ' +
        this.inviteLink;
      return `mailto:${mailto}?subject=${subject}&body=${body}`;
    },
    inviteLink() {
      return this.isApp
        ? `${BASE_URL}/?invite_code=${this.referralData.invite_code}`
        : `${window.location.origin}/?invite_code=${this.referralData.invite_code}`;
    },
    getBanner() {
      return this.$store.getters.getterBanner;
    }
  },
  methods: {
    async getInitData() {
      try {
        const res = await ReferralService.getReferral(this.accountId);

        this.referralData = { ...res.data.data };
      } catch (error) {
        console.error(error);
      }
    },
    copyTextToClipboard() {
      if (this.isCopy) return;
      let textArea = document.createElement('textArea');
      let range, selection;
      textArea.readOnly = true;
      textArea.contentEditable = true;
      textArea.value = this.inviteLink;
      document.getElementById('myInviteCode').appendChild(textArea);
      if (navigator.userAgent.match(/ipad|iphone/i)) {
        // iOS
        range = document.createRange();
        range.selectNodeContents(textArea);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else textArea.select();
      document.execCommand('copy');
      document.getElementById('myInviteCode').removeChild(textArea);
      this.isCopy = true;
      setTimeout(() => {
        this.isCopy = false;
      }, 1000);
    },
    handleShareFb() {
      window.open(
        'https://www.facebook.com/sharer/sharer.php?u=' + this.inviteLink,
        '',
        'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600'
      );
    },
    async handleShareNow() {
      if (window.ReactNativeWebView) {
        const data = {
          message: 'Mình đang mua vé số Vietlott trên ứng dụng Tài Lộc, hãy download và nắm bắt vận may…',
          url: this.inviteLink
        };
        this.sendMessageToApp(JSON.stringify({ event: 'SHARE', data }));
      } else {
        const shareData = {
          text: 'Mình đang mua vé số Vietlott trên ứng dụng Tài Lộc, hãy download và nắm bắt vận may…',
          url: this.inviteLink
        };

        if (navigator.share) {
          navigator.share(shareData);
          // .then(() => {
          //   alert('Thanks for sharing!');
          // })
          // .catch((err) => {
          //   alert('err', err);
          // });
        }
      }
    },

    async moveRemainCommission() {
      // do that
      // this.referralData.remain_commission
      if (this.referralData.remain_commission < 1) {
        this.setModalNoti(true);
        return;
      }
      try {
        const res = await ReferralService.changeCommission(this.accountId, this.referralData.remain_commission);
        this.referralData.remain_commission = res.data.data?.remain_commission || 0;
        if (res.data.data?.deposit_wallet) {
          this.$store.commit('setDepositBalance', res.data.data?.deposit_wallet);
        }
        Noti.fire({
          icon: 'success',
          title: 'Chuyển hoa hồng vào ví thành công.',
          showConfirmButton: false,
          timer: 2000
        });
      } catch (error) {
        console.error(error);
        Noti.fire({
          icon: 'error',
          title: error.response?.data?.data?.message || 'Chuyển tiền không thành công. Vui lòng thử lại !',
          showConfirmButton: false,
          timer: 2000
        });
      }
    },
    linkToList() {
      if (this.referralData.total_friends > 0) {
        this.$router.push('/tai-khoan/danh-sach-ban-gioi-thieu/');
      }
    },
    setModalNoti(newValue) {
      this.isOpenNoti = newValue;
    }
  }
};
</script>

<style lang="scss">
.inviteFriend {
  color: #666666;
  .banner {
    // object-fit: contain;
    img {
      height: 180px;
      width: 100%;
      object-fit: cover;
    }
  }

  .groupInfoCtn {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .inviteInfo {
    padding: 15px 20px 18px;
    border-radius: 3px;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;

    display: flex;
    align-items: center;

    transform: translateY(-15px);

    &__content {
      width: 100%;
      p:last-child {
        color: #333333;
        font-size: 1.125rem;
        font-weight: bold;
      }
    }

    .btn {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      min-width: 147px;
      height: 40px;

      border-radius: 3px;
      background-color: $primaryColor;
      font-weight: 600;
      color: #ffffff;
      text-transform: uppercase;
    }

    &__groupAction {
      &__btn {
        padding: 10px;
        margin-left: 10px;
        color: #ffffff;
        text-transform: uppercase;
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        &.fb {
          background-color: #00509d;
          fill: #ffffff;
        }
        &.mail {
          background-color: #0075cc;
          fill: #ffffff;
        }
        &.copy {
          background-color: #e5edf5;
          color: #00509d;
          &:hover {
            background-color: darken(#e5edf5, 5%);
          }
        }
        img {
          margin-right: 8px;
        }
      }
    }
  }

  .groupInfo {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;

    &__title {
      font-size: 1.125rem;
      line-height: 1.22;
      color: #666666;
      margin-bottom: 5px;
    }

    &__boxInfo {
      flex-grow: 1;
      padding: 15px 10px;
      border-radius: 3px;
      background-color: #ffffff;
      & > div {
        display: flex;
      }
    }
  }

  .nextIcon {
    position: relative;
    padding-right: 18px;
    &::after {
      content: ' ';
      position: absolute;
      right: 0;
      display: inline-block;
      width: 9px;
      height: 9px;
      border-top: 2px solid #999999;
      border-right: 2px solid #999999;
      border-left: none;
      transform: rotate(45deg) translate(-50%, -17%);
      top: 50%;
    }
  }

  .addTowallet {
    width: 325px;
    height: 40px;
    border-radius: 3px;
    background-color: #e5edf5;

    font-weight: 600;
    text-align: center;
    color: #00509d;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    text-transform: uppercase;
  }
  hr {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .bold {
    font-weight: 600;
  }
}

.customButton {
  .actionGroup__btn--confirm {
    max-width: 100% !important;
  }
}

.newNoti {
  padding: 13px 11px;
  border-radius: 3px;
  border: 1px dashed #fccb1a;
  background-color: #fffbeb;

  ul {
    list-style: initial;
    padding-left: 25px;
  }

  li {
    font-style: italic;
    font-size: 15px;
    color: $colorBlack;
    ::marker {
      font-size: 16px;
    }
  }

  h5 {
    color: #d6af24;
    font-size: 15px;
    font-weight: bold;
  }
}
@media (min-width: 720px) {
  .inviteFriend {
    .inviteInfo {
      &__content {
        width: unset;
        margin-right: auto;
      }
    }
  }
}
@media (min-width: map-get($grid-breakpoints, 'lg')) {
  .inviteFriend {
    .inviteInfo {
      transform: translateY(0);
      padding: 20px;
    }
    .newNoti {
      margin-top: 10px;
    }
    .groupInfoCtn {
      margin-top: 20px;
      flex-direction: row;
    }
    .groupInfo {
      margin-top: 0;
      margin-bottom: 0;
      // height: 100%;
      &:last-child {
        margin-left: 17px;
      }

      &__boxInfo {
        padding: 15px 17px;
        height: 100%;
        & > div {
          flex-direction: column;
          align-items: center;

          .ml-auto {
            margin-left: unset !important;
          }
        }
      }
    }

    .nextIcon {
      &::after {
        display: none;
      }
    }
    .bold {
      font-size: 1.5625rem;
      color: #333333;
    }

    .addTowallet {
      margin-top: 0px;
      padding: 10px 20px;
      width: unset !important;
    }

    .btnShowList {
      font-size: 0.875rem;
      font-weight: 600;
      color: #00509d;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
  .customDialogInviteFriend {
    max-width: 344px !important;
  }
}
</style>
